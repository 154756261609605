
//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Redirect",
})

//组件函数
export default class extends Vue {
  //创建时调用
  created() {
    //数据赋值
    const { params, query } = this.$route;
    const { path } = params;

    //切换界面
    this.$router.replace({ path: "/" + path, query }).catch((err) => {
      console.warn(err);
    });
  }

  render() {}
}
